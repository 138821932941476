import { IListCodeDescValueDto, ListCodeDesc } from "../common-list.dto";
import { IPOAItemModelDto } from "../fee.dto";

export const civilBillingStageControlledWork = 1;
export const civilBillingStageLicensedWork = 2;
export const civilBillingStageLegalHelp = 3;

export const crimeBillingStageInvestigations = 1;
export const crimeBillingStateProceedings = 2;
export const crimeBillingStageAppealsReviews = 3;
export const crimeBillingStagePrisonLaw = 4;

export const civilDARList = [
  "Designated Accredited Representative(DAR)",
  "Non - DAR - Employee",
  "Non - DAR - Agent",
  "Non - DAR - Counsel",
  "Not Applicable",
];

export interface IValidationDto {
  error: string;
  isDisabled: boolean;
  isRequired: boolean;
}

export interface IInvoiceInitDto {
  billingStageId: number;
  invoiceId: string;
  isReversedOrReversal: boolean;
  matterTypeId: number;
  matterTypeName: string;
}

export interface IInvoiceDto {
  invoiceId?: string;
  matterId?: string;
  externalURL?: string;
  externalJSON?: IInvoiceExternalJSONModel;
  invoiceDate?: string; // Date
  invoiceNumber?: number;
  invoiceDesc?: string;
  timeRecordGuids?: string[];
  reversedOrReversal?: number;
  reversalId?: string;
  semaphoreId?: string;
  totalAdjustments?: number;
  totalApplied?: number;
  warningAcknowledgments?: number[];
  status?: number;
  deleted?: number;
}

export interface IInvoiceExternalJSONModel {
  openType?: string;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  windowTitle?: string;
  closeHandler?: string;
  matterTypeId?: number;
  billingStageId?: number;
  ffFeeEarnerId?: string;
  dateConcluded?: string; // Date
  fixedFeeVat?: number;
  profitCostsVat?: number;
  travelVat?: number;
  waitingVat?: number;
  fixedFee?: number;
  profitCosts?: number;
  travelCosts?: number;
  waitingCosts?: number;
  disbExVat?: number;
  disbVat?: number;
  stage?: string;
  outcome?: string;
  adviceTime?: number;
  travelTime?: number;
  waitingTime?: number;
  isClaimExceptional?: boolean;
  fixedFeeValue?: number;
  fixedFeeLimit?: number;
  crime?: ICrimeJSON;
  crown?: ICrownJSON;
  civil?: ICivilJSON;
  poa?: IPOAJSON;
  isPOA?: boolean;
  poaFixedFeeTotalExVat?: number;
  poaFixedFeeTotalVat?: number;
}

export interface ICrimeJSON {
  standardFeeCat?: string;
  offence?: string;
  dutySol?: boolean;
  youthCourt?: boolean;
  defendants?: number;
  attendances?: number;
  undesignatedArea?: boolean;
  policeStationIncludeTravel?: boolean;
}

export interface ICrownJSON {
  typeOfCase?: string;
  trialType?: string;
  offenceClass?: string;
  offence?: string;
  trialDays?: number;
  ppe?: number;
  committalForTrial?: number;
  defendantsForUplift?: number;
  basicTrialFee?: number;
  basicTrialFeeVat?: number;
  trialLengthFee?: number;
  trialLengthFeeVat?: number;
  ppeFee?: number;
  ppeFeeVat?: number;
  totalCaseFee?: number;
  totalCaseFeeVat?: number;
  committalFee?: number;
  committalFeeVat?: number;
  defendantUpliftFee?: number;
  defendantUpliftFeeVat?: number;
  advocateType?: string;
  agfsCategoryId?: string;
  agfsOffenceId?: string;
  agfsCaseTypeId?: string;
  trialStart?: string;
  trialEnd?: string;
  estimatedDays?: number;
  actualDays?: number;
  additionalCase?: number;
  withinOneMonth?: boolean;
  afterOneMonth?: boolean;
  additionalFee?: number;
  additionalFeeVat?: number;
  additionalCaseUplift?: number;
  additionalCaseUpliftVat?: number;
  dailyAttendanceFee?: number;
  dailyAttendanceFeeVat?: number;
  discontinued?: string;
  retrialStart?: string;
  retrialEnd?: string;
  retrialEstimatedDays?: number;
  retrialActualDays?: number;
  trialCrackedAt?: string;
  trialCrackedAtThird?: string;
  trialFixedDate?: string;
  trialFixedNoticeDate?: string;
  mainHearingDate?: string;
}

export interface ICivilJSON {
  claimTypeCode?: string;
  part1?: string;
  part2?: string;
  feeScheme?: string;
  counselExVat?: number;
  counselVat?: number;
  meetingsAttended?: string;
  costsDamagesRecovered?: number;
  numberOfMediationSessions?: number;
  mediationTime?: number;
  postalApplication?: boolean;
  postalApplicationClient2?: boolean;
  mediationAgreedProposal?: boolean;
  mediationOutreachCode?: string;
  //Imm BoltOn
  hoInterviews?: number;
  cmrhOral?: number;
  cmrhTelephone?: number;
  substantiveHearing?: boolean;
  ircSurgery?: boolean;
  ircSurgeryDate?: string;
  noOfClientsSeenAtSurgery?: number;
  noOfClientsResultingInLHMatter?: number;
  followOnWork?: string;
  nrm?: boolean;
  //MH BoltOn
  additionalTravel?: number;
  adjournedHearing?: number;
  boltOnTotalExVat?: number;
  boltOnTotalVat?: number;
  medicalReportsClaimed?: number;
  dar?: string;
  fasTotalExVat?: number;
  fasTotalVat?: number;
  cpgfsTotalExVat?: number;
  cpgfsTotalVat?: number;
  pflrsTotalExVat?: number;
  pflrsTotalVat?: number;
}

export interface IPOAJSON {
  items?: IPOAItemModelDto[];
  fASTotalExVat: number;
  fASTotalVat: number;
  disbTotalExVat: number;
  disbTotalVat: number;
  expertTotalExVat: number;
  expertTotalVat: number;
  profitCostTotalExVat: number;
  profitCostTotalVat: number;
}

export interface ICivilListsDto {
  billingStageId: number;
  claimTypeCodes: string[];
  matterTypeICodes: IListCodeDescValueDto[];
  matterTypeIICodes: ListCodeDesc[];
  outcomeCode: ListCodeDesc[];
  stageReached: ListCodeDesc[];
  followOnCodes: string[];
}

export interface ICrimeListsDto {
  billingStageId: number;
  categories: ICrimeCategory[];
  offences: ICrimeOffence[];
  outcomes: ICrimeOutcome[];
}

export interface IAgfsListsDto {
  billingStageId: number;
  trialTypes: TrialType[];
  advocateTypes: AdvocateType[];
  offenceCategories: OffenceCategory[];
  offenceBands: OffenceBand[];
  caseTypes: CaseType[];
}

export interface ICrimeCategory {
  code: string;
  description: string;
  show: boolean;
  stages: ICrimeStage[];
}

export interface ICrimeOffence {
  code: string;
  description: string;
}

export interface ICrimeOutcome {
  code: string;
  description: string;
}

export interface ICrimeStage {
  code: string;
  description: string;
  hasRates: boolean;
  fee: number;
  lowerFee: number;
  lowerLimit: number;
  higherFee: number;
  higherLimit: number;
  fixedFee: number;
  fixedFeeLimit: number;
}

export interface IFixedFeeExceptionItem {
  fixedFee: number;
  fixedFeeValue: number;
  fixedFeeLimit: number;
  fixedFeeVat: number;
  isClaimExceptional: boolean;
}

export interface ICivilFFModelDto {
  matterId?: string;
  billingStageId?: number;
  matterTypeId?: number;
  profitCost?: number;
  travelCost?: number;
  waitingCost?: number;
  cpgfsBoltOnCost?: number;
  pflrsBoltOnCost?: number;
  counselCost?: number;
  matterType1Value?: number;
  tableNo?: number;
  rate?: string;
  caseStageCode?: string;
  matterType2Code?: string;
  noOfSessions?: number;
  agreedProposal?: boolean;
  noOfAdjournedHearings?: number;
  dateFundingGranted?: string;
  elaClaimTypeCode?: string;
}

export interface ICivilTotalsDto {
  fixedFeeAmount: number;
  fixedFeeValue: number;
  fixedFeeLimit: number;
  isExceptional: boolean;
}

export interface TrialType {
  trialType_Grade: string;
  trialType_Description: string;
}

export interface AdvocateType {
  advocateType_Grade: string;
  advocateTypeDesc: string;
}

export interface OffenceCategory {
  offenceCategoryGuid: string;
  offenceCategoryID: number;
  offenceCategoryDesc: string;
}

export interface OffenceBand {
  offenceGuid: string;
  offenceCategoryID: number;
  offenceBandDesc: string;
  offenceBandName: string;
}

export interface CaseType {
  caseType_GUID: string;
  caseType_Name: string;
}

export interface ICrownListsDto {
  billingStageId: number;
}

export interface ILgfsListsDto {
  billingStageId: number;
  typeOfCases: ITypeOfCaseGroupDto[];
  offences: IOffenceDto[];
}

export interface ITypeOfCaseGroupDto {
  typeOfCase: string;
  trialTypesList: string[];
}

export interface IOffenceDto {
  offenceClass: string;
  offencesDescList: string[];
}

export interface ILgfsTotalsDto {
  basicTrialFee: number;
  basicTrialFeeVat: number;
  trialLengthProxyFee: number;
  trialLengthProxyFeeVat: number;
  ppeProxyFee: number;
  ppeProxyFeeVat: number;
  committalForTrialFee: number;
  committalForTrialFeeVat: number;
  defendantUpliftsFee: number;
  defendantUpliftsFeeVat: number;
  totalCaseFee: number;
  totalCaseFeeVat: number;
  valueExVat: number;
  valueVat: number;
}

export interface IAgfsTotalsDto {
  basicTrialFee: number;
  basicTrialFeeVat: number;
  dailyAttendanceFee: number;
  dailyAttendanceFeeVat: number;
  totalCaseFee: number;
  totalCaseFeeVat: number;
  defendantUpliftsFee: number;
  defendantUpliftsFeeVat: number;
  additionalCaseUplift: number;
  additionalCaseUpliftVat: number;
  valueExVat: number;
  valueVat: number;
}

export interface ICCCDSuccessDto {
  message: string[];
}
