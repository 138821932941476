import {
  Button,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getUsers,
  setLoader,
  setValidationErrors,
} from "../../app/store/app/appSlice";
import {
  clearSemaphore,
  createDocument,
  createInvoice,
  getCivilInvoiceLists,
  getInvoice,
  getItemsForInvoice,
  getPrecedentList,
  postCivilFixedFee,
  reverseInvoice,
  setInit,
  setInvoice,
  setPrecedentId,
  setPrintingEnabled,
  updateInvoice,
} from "../../app/store/invoice/invoiceSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import {
  IDocBuilderModelDto,
  IDocModelDto,
} from "../../shared/dto/document.dto";
import {
  ICivilFFModelDto,
  ICivilTotalsDto,
  IInvoiceDto,
  IValidationDto,
  civilBillingStageControlledWork,
  civilBillingStageLegalHelp,
  civilDARList,
} from "../../shared/dto/invoice/invoice.dto";
import wordIcon from "../../shared/images/word.png";
import { isDisbursementsOnly } from "../../shared/utils/disbursements-utils";
import {
  calculateBalanceDue,
  createInvoiceDocument,
  printReport,
} from "../../shared/utils/invoice-utils";
import {
  close,
  onBeforeClose,
  getDecodedToken,
  openDocument,
  openReport,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  formatDateForAPI,
  formatValue,
  getFormattedUFN,
  newGuid,
} from "../../shared/utils/utils";
import { validate } from "../../shared/utils/validation-utils";
import CustomCheckbox from "../components/custom-checkbox";
import CustomDialog from "../components/custom-dialog";
import LocalDatePicker from "../components/date-picker";
import Footer from "../components/footer";
import InvoiceErrorDialog from "../components/invoice-error-dialog";
import NumberDisplay from "../components/number-display";
import NumberInput from "../components/number-input";
import ReversalInfo from "../components/reversal-info";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { listStatusItems } from "./lists";
import { useAppInit } from "../../shared/hooks/use-app-init";
import InvoiceDeleteButton from "../components/invoice-delete-button";
import ReverseIcon from "../../shared/images/reverse";

const InvoiceCivilDetails: FC = () => {
  const dispatch = useAppDispatch();

  const invoice = useAppSelector((state) => state.invoice);

  const isInvoiceDetailsInitialised = useRef(false);
  const isInvoiceInitialised = useRef(false);
  const isMatterDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const invoiceRef = useRef<IInvoiceDto | undefined>(undefined);
  const decodedToken = useRef<any>(undefined);

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.invoice.saving);
  const userList = useSelector((state: RootState) => state.app.users);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<
    IInvoiceDto | undefined
  >(undefined);
  const [notificationMessage, setNotificationMessage] = useState<
    string | undefined
  >(undefined);
  const [precedentMenuAnchorEl, setPrecedentMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const precedentMenuOpen = Boolean(precedentMenuAnchorEl);
  const [showUnsavedData, setShowUnsavedData] = useState(false);
  const [toBeReversed, setToBeReversed] = useState(false);

  const [searchParams] = useSearchParams();

  const infoIcon = require("../../shared/images/info.png");
  const printerIcon = require("../../shared/images/printer-grey.png");

  const [claimTypeValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const [darValidator, setDARValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: true,
    isRequired: false,
  });

  const [dateConcludedValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const [feeEarnerValidator, setFeeEarnerValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: false,
  });

  const [followOnWorkValidator, setFollowOnWorkValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: true,
      isRequired: false,
    });

  const [invoiceDateValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const [matterType1Validator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const [matterType2Validator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const [medicalReportsClaimedValidator, setMedicalReportsClaimedValidator] =
    useState<IValidationDto>({
      error: "",
      isDisabled: true,
      isRequired: false,
    });

  const [outcomeValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: true,
  });

  const isFormDisabled =
    invoice.init.isReversedOrReversal ||
    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
    toBeReversed ||
    !!invoice.init.invoiceId;

  const convertFormModelToInvoice = (tempInvoice: IInvoiceDto): IInvoiceDto => {
    let invoice: IInvoiceDto = {
      invoiceId: tempInvoice.invoiceId,
      matterId: tempInvoice.matterId,
      externalURL: tempInvoice.externalURL,
      externalJSON: {
        openType: tempInvoice.externalJSON?.openType,
        width: tempInvoice.externalJSON?.width,
        height: tempInvoice.externalJSON?.height,
        minWidth: tempInvoice.externalJSON?.minWidth,
        minHeight: tempInvoice.externalJSON?.minHeight,
        windowTitle: tempInvoice.externalJSON?.windowTitle,
        closeHandler: tempInvoice.externalJSON?.closeHandler,
        matterTypeId: tempInvoice.externalJSON?.matterTypeId,
        billingStageId: tempInvoice.externalJSON?.billingStageId,
        ffFeeEarnerId: tempInvoice.externalJSON?.ffFeeEarnerId,
        dateConcluded: tempInvoice.externalJSON?.dateConcluded,
        fixedFee: tempInvoice.externalJSON?.fixedFee,
        fixedFeeVat: tempInvoice.externalJSON?.fixedFeeVat,
        isClaimExceptional: tempInvoice.externalJSON?.isClaimExceptional,
        fixedFeeValue: tempInvoice.externalJSON?.fixedFeeValue,
        fixedFeeLimit: tempInvoice.externalJSON?.fixedFeeLimit,
        profitCosts: tempInvoice.externalJSON?.profitCosts,
        travelCosts: tempInvoice.externalJSON?.travelCosts,
        waitingCosts: tempInvoice.externalJSON?.waitingCosts,

        profitCostsVat: tempInvoice.externalJSON?.profitCostsVat,
        travelVat: tempInvoice.externalJSON?.travelVat,
        waitingVat: tempInvoice.externalJSON?.waitingVat,

        disbExVat: tempInvoice.externalJSON?.disbExVat,
        disbVat: tempInvoice.externalJSON?.disbVat,
        stage: tempInvoice.externalJSON?.stage,
        outcome: tempInvoice.externalJSON?.outcome,
        adviceTime: tempInvoice.externalJSON?.adviceTime,
        travelTime: tempInvoice.externalJSON?.travelTime,
        waitingTime: tempInvoice.externalJSON?.waitingTime,
        civil: {
          claimTypeCode: tempInvoice.externalJSON?.civil?.claimTypeCode,
          part1: tempInvoice.externalJSON?.civil?.part1,
          part2: tempInvoice.externalJSON?.civil?.part2,
          feeScheme: tempInvoice.externalJSON?.civil?.claimTypeCode,
          counselExVat: tempInvoice.externalJSON?.civil?.counselExVat,
          counselVat: tempInvoice.externalJSON?.civil?.counselVat,
          postalApplication: tempInvoice.externalJSON?.civil?.postalApplication,
          postalApplicationClient2:
            tempInvoice.externalJSON?.civil?.postalApplicationClient2,
          mediationAgreedProposal:
            tempInvoice.externalJSON?.civil?.mediationAgreedProposal,
          mediationOutreachCode:
            tempInvoice.externalJSON?.civil?.mediationOutreachCode,
          numberOfMediationSessions:
            tempInvoice.externalJSON?.civil?.numberOfMediationSessions,
          mediationTime: tempInvoice.externalJSON?.civil?.mediationTime,
          //Imm BoltOn
          hoInterviews: tempInvoice.externalJSON?.civil?.hoInterviews,
          cmrhOral: tempInvoice.externalJSON?.civil?.cmrhOral,
          cmrhTelephone: tempInvoice.externalJSON?.civil?.cmrhTelephone,
          substantiveHearing:
            tempInvoice.externalJSON?.civil?.substantiveHearing,
          ircSurgery: tempInvoice.externalJSON?.civil?.ircSurgery,
          ircSurgeryDate: tempInvoice.externalJSON?.civil?.ircSurgeryDate,
          noOfClientsSeenAtSurgery:
            tempInvoice.externalJSON?.civil?.noOfClientsSeenAtSurgery,
          noOfClientsResultingInLHMatter:
            tempInvoice.externalJSON?.civil?.noOfClientsResultingInLHMatter,
          followOnWork: tempInvoice.externalJSON?.civil?.followOnWork,
          nrm: tempInvoice.externalJSON?.civil?.nrm,
          //MH BoltOn
          additionalTravel: tempInvoice.externalJSON?.civil?.additionalTravel,
          adjournedHearing: tempInvoice.externalJSON?.civil?.adjournedHearing,
          boltOnTotalExVat: tempInvoice.externalJSON?.civil?.boltOnTotalExVat,
          boltOnTotalVat: tempInvoice.externalJSON?.civil?.boltOnTotalVat,
          medicalReportsClaimed:
            tempInvoice.externalJSON?.civil?.medicalReportsClaimed,
          dar: tempInvoice.externalJSON?.civil?.dar,
          // FM BoltOn
          fasTotalExVat: tempInvoice.externalJSON?.civil?.fasTotalExVat,
          fasTotalVat: tempInvoice.externalJSON?.civil?.fasTotalVat,
          cpgfsTotalExVat: tempInvoice.externalJSON?.civil?.cpgfsTotalExVat,
          cpgfsTotalVat: tempInvoice.externalJSON?.civil?.cpgfsTotalVat,
          pflrsTotalExVat: tempInvoice.externalJSON?.civil?.pflrsTotalExVat,
          pflrsTotalVat: tempInvoice.externalJSON?.civil?.pflrsTotalVat,
        },
        poa: tempInvoice.externalJSON?.poa,
        poaFixedFeeTotalExVat: tempInvoice.externalJSON?.poaFixedFeeTotalExVat,
        poaFixedFeeTotalVat: tempInvoice.externalJSON?.poaFixedFeeTotalVat,
      },
      invoiceDate: tempInvoice.invoiceDate,
      invoiceNumber: tempInvoice.invoiceNumber,
      invoiceDesc: tempInvoice.invoiceDesc,
      timeRecordGuids: tempInvoice.timeRecordGuids,
      reversalId: tempInvoice.reversalId,
      reversedOrReversal: tempInvoice.reversedOrReversal,
      semaphoreId: tempInvoice.semaphoreId,
      warningAcknowledgments: tempInvoice.warningAcknowledgments,
      status: tempInvoice.status,
      deleted: tempInvoice.deleted,
      totalAdjustments: tempInvoice.totalAdjustments,
      totalApplied: tempInvoice.totalApplied,
    };
    return invoice;
  };

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "id",
      headerName: "ID",
      width: 0,
    },
    {
      disableColumnMenu: true,
      field: "description",
      flex: 1,
      headerName: "DESCRIPTION",
      sortable: false,
      renderCell: (params: any) => {
        if (params.value === "Fixed Fee") {
          return (
            <>
              {params.value}
              <Tooltip
                style={{ paddingLeft: 5 }}
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Fixed Fee:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON
                          ?.isClaimExceptional &&
                          currentInvoiceDetails.externalJSON.fixedFeeValue !== 0
                          ? 0
                          : currentInvoiceDetails?.externalJSON?.fixedFeeValue,
                        invoice.init.isReversedOrReversal,
                        true,
                        true
                      )}
                    <br />
                    Fee Limit:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON
                          ?.isClaimExceptional &&
                          currentInvoiceDetails.externalJSON.fixedFeeValue !== 0
                          ? 0
                          : currentInvoiceDetails?.externalJSON?.fixedFeeLimit,
                        invoice.init.isReversedOrReversal,
                        true,
                        true
                      )}
                  </div>
                }
              >
                <img alt="warning" src={infoIcon} />
              </Tooltip>
              {currentInvoiceDetails?.externalJSON?.isClaimExceptional &&
                currentInvoiceDetails.externalJSON.fixedFeeValue !== 0 && (
                  <Typography color="grey" marginLeft={6}>
                    (This claim is Exceptional)
                  </Typography>
                )}
            </>
          );
        }

        return params.value;
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "time",
      headerAlign: "center",
      headerName: "TIME",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          notCurrency
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "amount",
      headerAlign: "center",
      headerName: "AMOUNT",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "tax",
      headerAlign: "center",
      headerName: "TAX",
      sortable: false,
      type: "number",
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
  ];

  const handleClose = useCallback(() => {
    if (invoiceRef.current && invoiceRef.current.semaphoreId) {
      dispatch(clearSemaphore(invoiceRef.current.semaphoreId)).then(() => {
        close(sdkApi);
      });
    } else {
      close(sdkApi);
    }
  }, [dispatch, sdkApi]);

  const getData = useCallback(() => {
    if (!isInvoiceInitialised.current) {
      isInvoiceInitialised.current = true;
      getDecodedToken(sdkApi).then((payload) => {
        decodedToken.current = payload;
      });
      dispatch(
        setInit({
          billingStageId: searchParams.get("billingstageid"),
          invoiceId: searchParams.get("invoiceid"),
          isReversedOrReversal: searchParams.get("reversal")
            ? searchParams.get("reversal")
            : false,
          matterTypeId: appInit?.laMatterTypeId,
          matterTypeName: appInit?.laMatterTypeName,
        })
      );

      dispatch(getMatterDetails(false)).then(() => {
        dispatch(getUsers());
      });
    }
  }, [dispatch, appInit, searchParams, sdkApi]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !isMatterDetailsInitialised.current) {
      isMatterDetailsInitialised.current = true;
      dispatch(
        getCivilInvoiceLists({
          matterTypeId: matterDetails?.legalAidObj?.matterTypeId,
          billingStageId: invoice?.init?.billingStageId,
          tableNo: matterDetails?.legalAidObj?.tableNo,
          rate: matterDetails?.legalAidObj?.rate,
        })
      )
        .then(() => {
          dispatch(
            getPrecedentList({
              matterTypeId: matterDetails?.legalAidObj?.matterTypeId,
              billingStageId: invoice?.init?.billingStageId,
            })
          );
        })
        .then(() => {
          if (invoice.init.invoiceId) {
            dispatch(getInvoice(invoice.init.invoiceId)).then((action) => {
              setWindowTitle(
                `${matterDetails?.matterReference || ""} ${
                  matterDetails?.matterDescription || ""
                }`,
                sdkApi,
                60,
                action.payload.externalJSON?.windowTitle
                  ? `- ${action.payload.externalJSON?.windowTitle}`
                  : ""
              );
            });
          } else if (invoice.init.billingStageId) {
            dispatch(
              getItemsForInvoice({
                matterId: matterDetails?.matterId,
                billingStageId: invoice?.init.billingStageId,
                disbursementsOnly: false,
              })
            );
            setWindowTitle(
              `${matterDetails?.matterReference || ""} ${
                matterDetails?.matterDescription || ""
              }`,
              sdkApi,
              60,
              "- New Invoice"
            );
          }
        });
    }
  }, [
    dispatch,
    matterDetails,
    invoice.init,
    isMatterDetailsInitialised,
    sdkApi,
  ]);

  useEffect(() => {
    if (!!invoice.invoice && !isInvoiceDetailsInitialised.current) {
      isInvoiceDetailsInitialised.current = true;

      if (invoice.civilLists?.claimTypeCodes.length === 1) {
        const tempInvoice = {
          ...invoice.invoice,
          externalJSON: {
            ...invoice.invoice.externalJSON,
            civil: {
              ...invoice.invoice.externalJSON?.civil,
              claimTypeCode: invoice.civilLists?.claimTypeCodes[0],
              feeScheme: invoice.civilLists?.claimTypeCodes[0],
            },
          },
        } as IInvoiceDto;
        setCurrentInvoiceDetails(tempInvoice);
        invoiceRef.current = tempInvoice;
        dispatch(setInvoice(tempInvoice));
      } else {
        setCurrentInvoiceDetails(invoice.invoice);
        invoiceRef.current = invoice.invoice;
      }
    }
  }, [
    currentInvoiceDetails,
    invoice.civilLists?.claimTypeCodes,
    invoice.invoice,
    isInvoiceDetailsInitialised,
    dispatch,
  ]);

  useEffect(() => {
    invoiceRef.current = currentInvoiceDetails;
  }, [currentInvoiceDetails]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi && !!invoice.invoice) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => {
        if (
          JSON.stringify(invoice.invoice) !== JSON.stringify(invoiceRef.current)
        ) {
          setShowUnsavedData(true);
        } else {
          handleClose();
        }
      }, sdkApi);
    }
  }, [sdkApi, invoice.invoice, handleClose]);

  const getTotals = () => {
    let calculationLabel: string = "";
    let totalCost: number = 0.0;
    let totalTime =
      (currentInvoiceDetails?.externalJSON?.adviceTime || 0) +
      (currentInvoiceDetails?.externalJSON?.travelTime || 0) +
      (currentInvoiceDetails?.externalJSON?.waitingTime || 0);
    let totalVat: number = 0.0;

    if (
      !(currentInvoiceDetails?.externalJSON?.fixedFee || 0) ||
      currentInvoiceDetails?.externalJSON?.fixedFeeValue === 0 ||
      currentInvoiceDetails?.externalJSON?.isClaimExceptional
    ) {
      totalVat =
        (currentInvoiceDetails?.externalJSON?.profitCostsVat || 0) +
        (currentInvoiceDetails?.externalJSON?.travelVat || 0) +
        (currentInvoiceDetails?.externalJSON?.waitingVat || 0) +
        (currentInvoiceDetails?.externalJSON?.civil?.boltOnTotalVat || 0);
      totalCost =
        (currentInvoiceDetails?.externalJSON?.profitCosts || 0) +
        (currentInvoiceDetails?.externalJSON?.travelCosts || 0) +
        (currentInvoiceDetails?.externalJSON?.waitingCosts || 0) +
        (currentInvoiceDetails?.externalJSON?.civil?.boltOnTotalExVat || 0);
      calculationLabel = "Profit Cost + Travel + Waiting + Bolt-On Payments";
    } else {
      totalVat =
        (currentInvoiceDetails?.externalJSON?.fixedFeeVat || 0) +
        (currentInvoiceDetails?.externalJSON?.civil?.boltOnTotalVat || 0);
      totalCost =
        (currentInvoiceDetails?.externalJSON?.fixedFee || 0) +
        (currentInvoiceDetails?.externalJSON?.civil?.boltOnTotalExVat || 0);
      calculationLabel = "Fixed Fee + Bolt-On Payments";
    }

    if (
      isDisbursementsOnly(
        currentInvoiceDetails?.externalJSON?.civil?.claimTypeCode || "",
        currentInvoiceDetails?.externalJSON?.matterTypeId || 0
      )
    )
      calculationLabel = "Disbursements";
    else calculationLabel += " + Disbursements";

    totalCost += currentInvoiceDetails?.externalJSON?.disbExVat || 0;
    totalVat += currentInvoiceDetails?.externalJSON?.disbVat || 0;

    return {
      totalCost,
      totalTime,
      totalVat,
      calculationLabel,
    };
  };

  const getSetFixedFee = (invoiceDetails: IInvoiceDto) => {
    let billingStageId = invoiceDetails?.externalJSON?.billingStageId || 0;
    let matterTypeId = invoiceDetails?.externalJSON?.matterTypeId || 0;
    let matterTypeValue = 0;
    let elaClaimTypeCode = "";

    const matterType1 = invoiceDetails?.externalJSON?.civil?.part1;
    const matterType2 = invoiceDetails?.externalJSON?.civil?.part2;

    switch (billingStageId.toString()) {
      case civilBillingStageControlledWork.toString():
      case civilBillingStageLegalHelp.toString():
        switch (matterTypeId) {
          case 12: // immigration
            switch (matterType2) {
              case "IPST":
              case "IDAS":
              case "IASU":
              case "IUAD":
              case "IUAS":
                break;
              default:
                switch (matterType1) {
                  case "IALA":
                  case "IALB":
                  case "IACA":
                  case "IACB":
                  case "IMLA":
                  case "IMLB":
                  case "IMCA":
                  case "IMCB":
                  case "IACC":
                  case "IMCC":
                  case "IACE":
                  case "IACF":
                  case "IMCE":
                  case "IMCF":
                    if (
                      invoice.civilLists &&
                      invoice.civilLists.matterTypeICodes
                    ) {
                      matterTypeValue =
                        invoice.civilLists.matterTypeICodes.find(
                          (m) => m.code === matterType1
                        )?.value || 0;
                    }
                    break;
                  default:
                    break;
                }
                break;
            }
            break;
        }
        break;
    }

    if (billingStageId === 4)
      elaClaimTypeCode =
        invoiceDetails?.externalJSON?.civil?.claimTypeCode ===
        "Early Legal Advice"
          ? "ELA"
          : "ELAP";

    if (
      matterDetails &&
      matterDetails?.matterId &&
      !invoiceDetails?.invoiceId
    ) {
      let model: ICivilFFModelDto = {
        matterId: matterDetails.matterId,
        billingStageId: billingStageId,
        matterTypeId: matterTypeId,
        profitCost: invoiceDetails?.externalJSON?.profitCosts,
        travelCost: invoiceDetails?.externalJSON?.travelCosts,
        waitingCost: invoiceDetails?.externalJSON?.waitingCosts,
        cpgfsBoltOnCost: invoiceDetails?.externalJSON?.civil?.cpgfsTotalExVat,
        pflrsBoltOnCost: invoiceDetails?.externalJSON?.civil?.pflrsTotalExVat,
        counselCost: invoiceDetails?.externalJSON?.civil?.counselExVat,
        matterType1Value: matterTypeValue,
        tableNo: matterDetails.legalAidObj?.tableNo,
        rate: matterDetails.legalAidObj?.rate,
        caseStageCode: matterDetails.legalAidObj?.caseStageCode,
        matterType2Code: matterType2,
        noOfSessions:
          invoiceDetails?.externalJSON?.civil?.numberOfMediationSessions,
        agreedProposal:
          invoiceDetails?.externalJSON?.civil?.mediationAgreedProposal,
        noOfAdjournedHearings:
          invoiceDetails?.externalJSON?.civil?.adjournedHearing,
        dateFundingGranted: formatDateForAPI(
          matterDetails.legalAidObj?.immigration?.dateFundingGranted || ""
        ),
        elaClaimTypeCode: elaClaimTypeCode,
      };

      dispatch(postCivilFixedFee(model)).then((action: any) => {
        const payload = action.payload as ICivilTotalsDto;
        if (!invoiceDetails?.invoiceId && !invoice.init.isReversedOrReversal) {
          setFeeEarnerValidator({
            ...feeEarnerValidator,
            isDisabled: false,
            isRequired: true,
          });
        } else {
          setFeeEarnerValidator({
            ...feeEarnerValidator,
            isDisabled: true,
            isRequired: true,
          });
        }

        if (
          !isDisbursementsOnly(
            currentInvoiceDetails?.externalJSON?.civil?.claimTypeCode || "",
            currentInvoiceDetails?.externalJSON?.matterTypeId || 0
          )
        )
          setCurrentInvoiceDetails({
            ...invoiceDetails,
            externalJSON: {
              ...invoiceDetails?.externalJSON,
              fixedFee: payload.fixedFeeAmount,
              fixedFeeVat:
                payload.fixedFeeAmount * (matterDetails?.taxFree ? 0 : 0.2),
              fixedFeeValue: payload.fixedFeeValue,
              fixedFeeLimit: payload.fixedFeeLimit,
              isClaimExceptional: payload.isExceptional,
            },
          });
        else setCurrentInvoiceDetails(invoiceDetails);
      });
    } else setCurrentInvoiceDetails(invoiceDetails);
  };

  const getRequiredFields = (data: IInvoiceDto) => {
    let fields = [];

    if (claimTypeValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.civil?.claimTypeCode,
        label: "Claim Type",
      });

    if (darValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.civil?.dar,
        label: "DAR",
      });

    if (dateConcludedValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.dateConcluded,
        label: "Date Concluded",
      });

    if (feeEarnerValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.ffFeeEarnerId,
        label: "Fee Earner",
      });

    if (followOnWorkValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.civil?.followOnWork,
        label: "Follow on Work",
      });

    if (invoiceDateValidator.isRequired)
      fields.push({
        value: data?.invoiceDate,
        label: "Date Billed",
      });

    if (matterType1Validator.isRequired)
      fields.push({
        value: data?.externalJSON?.civil?.part1,
        label: isLicencedWorkExludingImmigration()
          ? "Case Stage"
          : "Matter Type 1",
      });

    if (matterType2Validator.isRequired)
      fields.push({
        value: data?.externalJSON?.civil?.part2,
        label: isLicencedWorkExludingImmigration()
          ? "Case Ended"
          : "Matter Type 2",
      });

    if (medicalReportsClaimedValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.civil?.medicalReportsClaimed,
        label: "Medical Reports Claimed",
      });

    if (outcomeValidator.isRequired && !isMediationAssesment())
      fields.push({
        value: data?.externalJSON?.outcome,
        label: isLicencedWorkExludingImmigration() ? "Result" : "Outcome",
      });
    return fields;
  };

  const isLicencedWorkExludingImmigration = () => {
    return (
      currentInvoiceDetails?.externalJSON?.billingStageId === 2 &&
      currentInvoiceDetails.externalJSON.matterTypeId !== 12
    );
  };

  const isMediation = (invoice?: IInvoiceDto) => {
    return invoice?.externalJSON?.matterTypeId === 5;
  };

  const isMediationAssesment = () => {
    return (
      matterDetails?.legalAidObj?.mediation?.mediationType ===
        "Assessment Alone [ASSA]" ||
      matterDetails?.legalAidObj?.mediation?.mediationType ===
        "Assessment Separate [ASSS]" ||
      matterDetails?.legalAidObj?.mediation?.mediationType ===
        "Assessment Together [ASST]"
    );
  };

  const isMentalHealth = () => {
    return currentInvoiceDetails?.externalJSON?.matterTypeId === 11;
  };

  const isImmigration = () => {
    return currentInvoiceDetails?.externalJSON?.matterTypeId === 12;
  };

  const getHelpUrl = () => {
    switch (currentInvoiceDetails?.externalJSON?.matterTypeId) {
      case 12:
        //Immigration
        return "https://community.leap.co.uk/s/article/Legal-Aid-Civil-Invoicing-an-Immigration-Controlled-Work-Claim";
      case 11:
        //Mental Health
        return "https://community.leap.co.uk/s/article/Legal-Aid-Civil-Invoicing-a-Mental-Health-Controlled-Work-Claim";
      case 5:
        //Mediation
        return "https://community.leap.co.uk/s/article/Legal-Aid-Civil-Mediation-Invoicing-a-Mediation-Matter";
      default:
        return "https://community.leap.co.uk/s/article/Legal-Aid-Civil-Invoicing-a-Family-Controlled-Work-Claim";
    }
  };

  const handleOnChangeClaimType = (value: any) => {
    let medicalReportsClaimed =
      currentInvoiceDetails?.externalJSON?.civil?.medicalReportsClaimed;
    let matterType1Value = currentInvoiceDetails?.externalJSON?.civil?.part1;

    if (!currentInvoiceDetails?.invoiceId) {
      if (value === "Mental Health Completed Matter Claim") {
        setMedicalReportsClaimedValidator({
          ...medicalReportsClaimedValidator,
          isDisabled: false,
        });
      } else {
        setMedicalReportsClaimedValidator({
          ...medicalReportsClaimedValidator,
          isDisabled: true,
        });
        medicalReportsClaimed = 0;
      }
    }

    dispatch(clearSemaphore(currentInvoiceDetails?.semaphoreId || "")).then(
      () => {
        dispatch(
          getItemsForInvoice({
            matterId: matterDetails?.matterId,
            billingStageId: invoice?.init.billingStageId,
            disbursementsOnly: isDisbursementsOnly(
              value,
              currentInvoiceDetails?.externalJSON?.matterTypeId || 0
            ),
          })
        ).then((action: any) => {
          const payload = action.payload as IInvoiceDto;

          if (payload) {
            if (isMediation(payload)) {
              if (value === "Assessment" && matterType1Value !== "ASSM") {
                matterType1Value = "ASSM";
              } else if (value === "Mediation" && matterType1Value !== "MEDI") {
                matterType1Value = "MEDI";
              }
            }

            const dar = enableDisableDAR(payload, value);
            const followOnWork = enableDisableFollowOnWork(
              matterType1Value,
              payload?.externalJSON?.civil?.part2
            );

            setCurrentInvoiceDetails({
              ...payload,
              externalJSON: {
                ...payload?.externalJSON,
                civil: {
                  ...payload?.externalJSON?.civil,
                  dar: dar,
                  claimTypeCode: value,
                  followOnWork: followOnWork,
                  medicalReportsClaimed: medicalReportsClaimed,
                  part1:
                    currentInvoiceDetails?.externalJSON?.billingStageId === 4
                      ? ""
                      : matterType1Value,
                },
              },
            });
          }
        });
      }
    );
  };

  const handleOnChangeMatterType1 = (value: any) => {
    const followOnWork = enableDisableFollowOnWork(
      value,
      currentInvoiceDetails?.externalJSON?.civil?.part2
    );

    let claimTypeCode =
      currentInvoiceDetails?.externalJSON?.civil?.claimTypeCode;
    if (isMediation(currentInvoiceDetails)) {
      if (claimTypeCode !== "Assessment" && value === "ASSM") {
        claimTypeCode = "Assessment";
      } else if (claimTypeCode !== "Mediation" && value === "MEDI") {
        claimTypeCode = "Mediation";
      }
    }

    getSetFixedFee({
      ...currentInvoiceDetails,
      externalJSON: {
        ...currentInvoiceDetails?.externalJSON,
        civil: {
          ...currentInvoiceDetails?.externalJSON?.civil,
          claimTypeCode: claimTypeCode,
          part1: value,
          followOnWork: followOnWork,
        },
      },
    });
  };

  const handleOnChangeMatterType2 = (value: any) => {
    const followOnWork = enableDisableFollowOnWork(
      currentInvoiceDetails?.externalJSON?.civil?.part1,
      value
    );

    getSetFixedFee({
      ...currentInvoiceDetails,
      externalJSON: {
        ...currentInvoiceDetails?.externalJSON,
        civil: {
          ...currentInvoiceDetails?.externalJSON?.civil,
          part2: value,
          followOnWork: followOnWork,
        },
      },
    });
  };

  const isBoltOn = () => {
    return (
      (currentInvoiceDetails?.externalJSON?.civil?.boltOnTotalExVat || 0) > 0
    );
  };

  const enableDisableDAR = (invoice: IInvoiceDto, claimTypeCode: string) => {
    let dar = invoice?.externalJSON?.civil?.dar || "";

    if (!invoice?.invoiceId) {
      if (
        (matterDetails?.legalAidObj?.caseStageCode === "MHL04" ||
          matterDetails?.legalAidObj?.caseStageCode === "MHL06" ||
          matterDetails?.legalAidObj?.caseStageCode === "MHL07" ||
          matterDetails?.legalAidObj?.caseStageCode === "MHL08") &&
        claimTypeCode === "Mental Health Completed Matter Claim"
      ) {
        setDARValidator({
          ...darValidator,
          isDisabled: false,
        });
      } else {
        setDARValidator({
          ...darValidator,
          isDisabled: true,
        });
        dar = "";
      }
    }

    return dar;
  };

  const enableDisableFollowOnWork = (
    matterType1?: string,
    matterType2?: string
  ) => {
    let followOnWork = "";

    if (matterType1 && matterType2 && !currentInvoiceDetails?.invoiceId) {
      if (
        (matterType1 === "IAXL" || matterType1 === "IMXL") &&
        matterType2 === "IPRN"
      ) {
        followOnWork = "";
        setFollowOnWorkValidator({
          ...followOnWorkValidator,
          isDisabled: false,
        });
      } else {
        followOnWork = "N/A";
        setFollowOnWorkValidator({
          ...followOnWorkValidator,
          isDisabled: true,
        });
      }
    }

    return followOnWork;
  };

  const getDataRows = () => {
    let dataRows = [
      {
        id: newGuid(),
        description: "Fixed Fee",
        amount: currentInvoiceDetails?.externalJSON?.fixedFee,
        tax: currentInvoiceDetails?.externalJSON?.fixedFeeVat,
      },
      {
        id: newGuid(),
        description: "Profit Cost",
        time: currentInvoiceDetails?.externalJSON?.adviceTime,
        amount: currentInvoiceDetails?.externalJSON?.profitCosts,
        tax: currentInvoiceDetails?.externalJSON?.profitCostsVat,
      },
      {
        id: newGuid(),
        description: "Travel",
        time: currentInvoiceDetails?.externalJSON?.travelTime,
        amount: currentInvoiceDetails?.externalJSON?.travelCosts,
        tax: currentInvoiceDetails?.externalJSON?.travelVat,
      },
      {
        id: newGuid(),
        description: "Waiting",
        time: currentInvoiceDetails?.externalJSON?.waitingTime,
        amount: currentInvoiceDetails?.externalJSON?.waitingCosts,
        tax: currentInvoiceDetails?.externalJSON?.waitingVat,
      },
      {
        id: newGuid(),
        description: "Disbursements",
        amount: currentInvoiceDetails?.externalJSON?.disbExVat,
        tax: currentInvoiceDetails?.externalJSON?.disbVat,
      },
    ];

    if (isBoltOn()) {
      dataRows.push({
        id: newGuid(),
        description: "Bolt-on Payments",
        amount: currentInvoiceDetails?.externalJSON?.civil?.boltOnTotalExVat,
        tax: currentInvoiceDetails?.externalJSON?.civil?.boltOnTotalVat,
      });
    }

    return dataRows;
  };

  const renderCriteria = () => {
    return (
      <>
        <div className="displayFlex">
          <div style={{ width: 675 }}>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex3" style={{ display: "inline-flex" }}>
                <TextField
                  disabled
                  value={matterDetails?.matterReference || ""}
                  variant="outlined"
                  style={{ width: 110 }}
                />
                <Typography
                  style={{ marginTop: 3, marginLeft: 5 }}
                  maxWidth={450}
                  minWidth={450}
                  noWrap
                >
                  {matterDetails?.matterDescription}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex1" />
          <div style={{ width: 260, paddingLeft: 25 }}>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel>Invoice No.</InputLabel>
              </div>
              <div className="flex1">
                <TextField
                  disabled
                  placeholder="Auto no"
                  variant="outlined"
                  value={currentInvoiceDetails?.invoiceNumber || ""}
                  style={{ width: 108 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="displayFlex">
          <div style={{ width: 425 }}>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel required={claimTypeValidator.isRequired}>
                  Claim Type
                </InputLabel>
              </div>
              <div className="flex3">
                <Select
                  disabled={claimTypeValidator.isDisabled || isFormDisabled}
                  onChange={(e: any) => {
                    handleOnChangeClaimType(e.target.value);
                  }}
                  required={claimTypeValidator.isRequired}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.civil?.claimTypeCode ||
                    ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {(!currentInvoiceDetails?.externalJSON?.civil
                    ?.claimTypeCode ||
                    currentInvoiceDetails?.externalJSON?.civil
                      ?.claimTypeCode === "") && <option value=""></option>}
                  {invoice.civilLists?.claimTypeCodes?.map(
                    (item: any, index: number) => (
                      <option key={`invoiceClaimType-${index}`} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel required={matterType1Validator.isRequired}>
                  {isLicencedWorkExludingImmigration()
                    ? "Case Stage"
                    : "Matter Type 1"}
                </InputLabel>
              </div>
              <div className="flex3">
                <Select
                  disabled={matterType1Validator.isDisabled || isFormDisabled}
                  onChange={(e: any) => {
                    handleOnChangeMatterType1(e.target.value);
                  }}
                  required={matterType1Validator.isRequired}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.civil?.part1 || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {(!currentInvoiceDetails?.externalJSON?.civil?.part1 ||
                    currentInvoiceDetails?.externalJSON?.civil?.part1 ===
                      "") && <option value=""></option>}
                  {invoice.civilLists?.matterTypeICodes.map(
                    (item: any, index: number) => (
                      <option
                        key={`invoiceMatterType1-${index}`}
                        value={item.code}
                      >
                        {item.code} {item.description}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel required={matterType2Validator.isRequired}>
                  {isLicencedWorkExludingImmigration()
                    ? "Case Ended"
                    : "Matter Type 2"}
                </InputLabel>
              </div>
              <div className="flex3">
                <Select
                  disabled={matterType2Validator.isDisabled || isFormDisabled}
                  onChange={(e: any) => {
                    handleOnChangeMatterType2(e.target.value);
                  }}
                  required={matterType2Validator.isRequired}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.civil?.part2 || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {(!currentInvoiceDetails?.externalJSON?.civil?.part2 ||
                    currentInvoiceDetails?.externalJSON?.civil?.part2 ===
                      "") && <option value=""></option>}
                  {invoice.civilLists?.matterTypeIICodes.map(
                    (item: any, index: number) => (
                      <option
                        key={`invoiceMatterType2-${index}`}
                        value={item.code}
                      >
                        {item.code} {item.description}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel
                  required={
                    outcomeValidator.isRequired && !isMediationAssesment()
                  }
                >
                  {isLicencedWorkExludingImmigration() ? "Result" : "Outcome"}
                </InputLabel>
              </div>
              <div className="flex3">
                <Select
                  disabled={outcomeValidator.isDisabled || isFormDisabled}
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        outcome: e.target.value,
                      },
                    });
                  }}
                  required={outcomeValidator.isRequired}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  value={currentInvoiceDetails?.externalJSON?.outcome || ""}
                  variant="outlined"
                  native
                  className="select"
                >
                  {(!currentInvoiceDetails?.externalJSON?.outcome ||
                    currentInvoiceDetails?.externalJSON?.outcome === "") && (
                    <option value=""></option>
                  )}
                  {invoice.civilLists?.outcomeCode?.map(
                    (item: any, index: number) => (
                      <option key={`invoiceOutcome-${index}`} value={item.code}>
                        {item.code} {item.description}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>
                  {isLicencedWorkExludingImmigration()
                    ? "ADR Prop/Used"
                    : isMediation(currentInvoiceDetails)
                    ? "Referral Code"
                    : "Stage"}
                </InputLabel>
              </div>
              <div className="flex3">
                <Select
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        stage: e.target.value,
                      },
                    });
                  }}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  disabled={isFormDisabled}
                  value={currentInvoiceDetails?.externalJSON?.stage || ""}
                  variant="outlined"
                  native
                  className="select"
                >
                  {(!currentInvoiceDetails?.externalJSON?.stage ||
                    currentInvoiceDetails?.externalJSON?.stage === "") && (
                    <option value=""></option>
                  )}
                  {invoice.civilLists?.stageReached.map(
                    (item: any, index: number) => (
                      <option key={`stage-${index}`} value={item.code}>
                        {item.code} {item.description}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex1" />
          <div style={{ width: 250 }}>
            <div>
              <div className="input-row-aligned">
                <div style={{ width: 145 }}>
                  <InputLabel>UFN</InputLabel>
                </div>
                <div className="flex1" />
                <TextField
                  disabled
                  fullWidth
                  value={
                    matterDetails?.legalAidObj?.ufnNumber
                      ? getFormattedUFN(
                          matterDetails?.legalAidObj?.ufnNumber,
                          matterDetails?.legalAidObj?.ufnDate
                        )
                      : ""
                  }
                  variant="outlined"
                  style={{
                    maxWidth: 150,
                    minWidth: 150,
                  }}
                />
              </div>
              {isMediation(currentInvoiceDetails) && (
                <>
                  <div className="input-row-aligned">
                    <div style={{ width: 145 }}>
                      <InputLabel>No. of Sessions</InputLabel>
                    </div>
                    <div className="flex1" />
                    <NumberInput
                      disabled={isFormDisabled}
                      updater={(value) => {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            civil: {
                              ...currentInvoiceDetails?.externalJSON?.civil,
                              numberOfMediationSessions: value,
                            },
                          },
                        });
                      }}
                      decimalScale={0}
                      value={
                        currentInvoiceDetails?.externalJSON?.civil
                          ?.numberOfMediationSessions || 0
                      }
                      width={70}
                    />
                  </div>
                  <div className="input-row-aligned">
                    <div style={{ width: 145 }}>
                      <InputLabel>Outreach Code</InputLabel>
                    </div>
                    <div className="flex1" />
                    <TextField
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e: any) => {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            civil: {
                              ...currentInvoiceDetails?.externalJSON?.civil,
                              mediationOutreachCode: e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ),
                            },
                          },
                        });
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                        },
                        width: "70px",
                      }}
                      disabled={isFormDisabled}
                      value={
                        currentInvoiceDetails?.externalJSON?.civil
                          ?.mediationOutreachCode || ""
                      }
                      variant="outlined"
                    />
                  </div>
                  <div className="input-row-aligned">
                    <div className="flex1">
                      <CustomCheckbox
                        label="Agreed Proposal"
                        disabled={isFormDisabled}
                        onChange={(value: any) => {
                          setCurrentInvoiceDetails({
                            ...currentInvoiceDetails,
                            externalJSON: {
                              ...currentInvoiceDetails?.externalJSON,
                              civil: {
                                ...currentInvoiceDetails?.externalJSON?.civil,
                                mediationAgreedProposal: value,
                              },
                            },
                          });
                        }}
                        value={
                          currentInvoiceDetails?.externalJSON?.civil
                            ?.mediationAgreedProposal || false
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              {isMentalHealth() && (
                <>
                  <div className="input-row-aligned">
                    <div style={{ width: 145 }}>
                      <InputLabel required={darValidator.isRequired}>
                        DAR
                      </InputLabel>
                    </div>
                    <div className="flex1" />
                    <Select
                      disabled={darValidator.isDisabled || isFormDisabled}
                      onChange={(e: any) => {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            civil: {
                              ...currentInvoiceDetails?.externalJSON?.civil,
                              dar: e.target.value,
                            },
                          },
                        });
                      }}
                      required={darValidator.isRequired}
                      value={
                        currentInvoiceDetails?.externalJSON?.civil?.dar || ""
                      }
                      style={{ minWidth: 150, maxWidth: 150 }}
                      variant="outlined"
                      native
                      className="select"
                    >
                      {(!currentInvoiceDetails?.externalJSON?.civil?.dar ||
                        currentInvoiceDetails?.externalJSON?.civil?.dar ===
                          "") && <option value=""></option>}
                      {civilDARList.map((item: any, index: number) => (
                        <option key={`dar-${index}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="input-row-aligned">
                    <div style={{ width: 145 }}>
                      <InputLabel
                        required={medicalReportsClaimedValidator.isRequired}
                      >
                        Medical Reports Claimed
                      </InputLabel>
                    </div>
                    <div className="flex1" />
                    <NumberInput
                      updater={(value) => {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            civil: {
                              ...currentInvoiceDetails?.externalJSON?.civil,
                              medicalReportsClaimed: value,
                            },
                          },
                        });
                      }}
                      disabled={isFormDisabled}
                      decimalScale={0}
                      value={
                        currentInvoiceDetails?.externalJSON?.civil
                          ?.medicalReportsClaimed || 0
                      }
                      width={70}
                    />
                  </div>
                </>
              )}
              {isImmigration() && (
                <div className="input-row-aligned">
                  <div style={{ width: 110 }}>
                    <InputLabel required={followOnWorkValidator.isRequired}>
                      Follow On Work
                    </InputLabel>
                  </div>
                  <div className="flex1" />
                  <Select
                    disabled={
                      followOnWorkValidator.isDisabled || isFormDisabled
                    }
                    onChange={(e: any) => {
                      setCurrentInvoiceDetails({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          civil: {
                            ...currentInvoiceDetails?.externalJSON?.civil,
                            followOnWork: e.target.value,
                          },
                        },
                      });
                    }}
                    required={followOnWorkValidator.isRequired}
                    sx={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={
                      currentInvoiceDetails?.externalJSON?.civil
                        ?.followOnWork || ""
                    }
                    variant="outlined"
                    native
                    className="select"
                  >
                    {(!currentInvoiceDetails?.externalJSON?.civil
                      ?.followOnWork ||
                      currentInvoiceDetails?.externalJSON?.civil
                        ?.followOnWork === "") && <option value=""></option>}
                    {invoice.civilLists?.followOnCodes.map(
                      (item: any, index: number) => (
                        <option key={`followOnCodes-${index}`} value={item}>
                          {item}
                        </option>
                      )
                    )}
                  </Select>
                </div>
              )}
              <div className="input-row-aligned">
                <div
                  style={
                    isMediation(currentInvoiceDetails) ? { paddingTop: 5 } : {}
                  }
                >
                  <CustomCheckbox
                    disabled={isFormDisabled}
                    label={
                      isMediation(currentInvoiceDetails)
                        ? "Postal Application Client 1"
                        : "Postal Application Accepted"
                    }
                    onChange={(value: any) => {
                      setCurrentInvoiceDetails({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          civil: {
                            ...currentInvoiceDetails?.externalJSON?.civil,
                            postalApplication: value,
                          },
                        },
                      });
                    }}
                    value={
                      currentInvoiceDetails?.externalJSON?.civil
                        ?.postalApplication || false
                    }
                  />
                </div>
                <div className="flex1" />
                {isMediation(currentInvoiceDetails) && (
                  <div style={{ paddingTop: 5 }}>
                    <CustomCheckbox
                      label="Client 2"
                      disableMargin
                      disabled={isFormDisabled}
                      onChange={(value: any) => {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            civil: {
                              ...currentInvoiceDetails?.externalJSON?.civil,
                              postalApplicationClient2: value,
                            },
                          },
                        });
                      }}
                      value={
                        currentInvoiceDetails?.externalJSON?.civil
                          ?.postalApplicationClient2 || false
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex1" />
          <div style={{ width: 260, paddingLeft: 25 }}>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required={dateConcludedValidator.isRequired}>
                  Date Concluded
                </InputLabel>
              </div>
              <div className="flex1" style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled}
                  onUpdate={(value: any) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.dateConcluded
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            dateConcluded: newValue,
                          },
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.dateConcluded
                      ? currentInvoiceDetails.externalJSON?.dateConcluded
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required={invoiceDateValidator.isRequired}>
                  Date Billed
                </InputLabel>
              </div>
              <div className="flex1" style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled && !toBeReversed}
                  onUpdate={(value: any) => {
                    if (value !== currentInvoiceDetails?.invoiceDate) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          invoiceDate: newValue,
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.invoiceDate
                      ? currentInvoiceDetails.invoiceDate
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel>Status</InputLabel>
              </div>
              <div className="flex4">
                <Select
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      status: e.target.value,
                    });
                  }}
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                  }}
                  disabled={
                    isFormDisabled && currentInvoiceDetails?.status === 3
                  }
                  value={
                    !!currentInvoiceDetails?.status ||
                    currentInvoiceDetails?.status === 0
                      ? currentInvoiceDetails?.status
                      : ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {listStatusItems.map((item: any, index: number) => (
                    <option key={`invoiceStatus-${index}`} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required={feeEarnerValidator.isRequired}>
                  Staff
                  <Tooltip
                    style={{ paddingLeft: 5 }}
                    title="Defines who will be allocated to the fixed fee. Only relevant if a fixed fee applies."
                  >
                    <img alt="warning" src={infoIcon} />
                  </Tooltip>
                </InputLabel>
              </div>
              <div className="flex4">
                <Select
                  disabled={feeEarnerValidator.isDisabled || isFormDisabled}
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        ffFeeEarnerId: e.target.value,
                      },
                    });
                  }}
                  required={feeEarnerValidator.isRequired}
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.ffFeeEarnerId || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {filterInactiveStaff(
                    userList,
                    currentInvoiceDetails?.externalJSON?.ffFeeEarnerId
                  )?.map((item: any, index: number) => (
                    <option key={`staff-${index}`} value={item.staffId}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderBreakdown = () => {
    return (
      <DataGridPro
        columns={columns}
        columnHeaderHeight={30}
        columnVisibilityModel={{
          id: false,
        }}
        disableRowSelectionOnClick
        getRowId={(row: any) => row.id}
        hideFooter
        initialState={{}}
        rowHeight={26}
        rows={getDataRows()}
        showCellVerticalBorder
        showColumnVerticalBorder
        sx={{
          backgroundColor: "#fff",
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
          ".MuiDataGrid-overlay": { color: "#0060bb" },
          ".MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
          },
          ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
        }}
      />
    );
  };

  const renderTotals = () => {
    const totals = getTotals();

    return (
      <div className="displayFlex">
        <div className="flex1">
          <Typography fontWeight={600} marginTop={1} sx={{ color: "#0160aa" }}>
            Total Breakdown
          </Typography>
          <Typography color="grey" marginLeft={2}>
            <i>{totals.calculationLabel}</i>
          </Typography>
          <div style={{ display: "inline-flex", marginTop: 10 }}>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalAdjustments}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">ADJUSTMENTS</Typography>
            </div>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalApplied}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">TOTAL PAID</Typography>
            </div>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={
                  currentInvoiceDetails?.externalJSON?.poaFixedFeeTotalExVat! +
                  currentInvoiceDetails?.externalJSON?.poaFixedFeeTotalVat!
                }
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">TOTAL POA</Typography>
            </div>
          </div>
        </div>
        <div>
          <div
            className="invoice-total-row"
            style={{
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>TOTAL</Typography>
            </div>
            <div style={{ width: 92 }}>
              <NumberDisplay
                value={totals.totalTime}
                notCurrency
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalCost}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
          </div>
          <div className="invoice-total-row">
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>INC. TAX</Typography>
            </div>
            <div style={{ width: 192 }}>
              <NumberDisplay
                value={totals.totalCost + totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
          </div>
          <div className="invoice-total-footer">
            <div style={{ width: 90 }}>
              <Typography style={{ color: "#db761d", fontWeight: 600 }}>
                BALANCE DUE
              </Typography>
            </div>
            <div style={{ width: 193 }}>
              <NumberDisplay
                value={calculateBalanceDue(
                  [
                    totals.totalCost,
                    totals.totalVat,
                    currentInvoiceDetails?.totalAdjustments || 0,
                  ],
                  [
                    currentInvoiceDetails?.totalApplied || 0,
                    currentInvoiceDetails?.externalJSON
                      ?.poaFixedFeeTotalExVat || 0,
                    currentInvoiceDetails?.externalJSON?.poaFixedFeeTotalVat ||
                      0,
                  ]
                )}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
                bold
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <TopBar
        leftComponents={
          <>
            <InvoiceDeleteButton
              invoiceDetails={currentInvoiceDetails}
              invoiceRef={invoiceRef.current}
            />
            <Tooltip placement="bottom-end" title="Reverse Transaction">
              <span>
                <IconButton
                  disabled={
                    !currentInvoiceDetails?.invoiceId ||
                    invoice.init.isReversedOrReversal ||
                    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                    toBeReversed
                  }
                  disableRipple
                  disableTouchRipple
                  onClick={() => {
                    setToBeReversed(true);
                  }}
                >
                  <ReverseIcon
                    disabled={
                      !currentInvoiceDetails?.invoiceId ||
                      invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                      toBeReversed
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
        centerComponents={
          <ReversalInfo
            isReversal={
              invoice.init.isReversedOrReversal ||
              Boolean(currentInvoiceDetails?.reversedOrReversal)
            }
          />
        }
        helpUrl={getHelpUrl()}
        rightComponents={
          <>
            <Tooltip placement="bottom-end" title="Send to Word">
              <IconButton
                aria-controls="precedent-menu"
                disableRipple
                disableTouchRipple
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setPrecedentMenuAnchorEl(event.currentTarget);
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="precedent options" src={wordIcon} />
              </IconButton>
            </Tooltip>
            <Menu
              id="precedent-menu"
              anchorEl={precedentMenuAnchorEl}
              onClose={() => {
                setPrecedentMenuAnchorEl(null);
              }}
              open={precedentMenuOpen}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {(invoice.precedentList || []).map((item: any, index: number) => (
                <MenuItem
                  data-precedent-value={item.guid}
                  key={`precedent-${index}`}
                  onClick={(e) => {
                    createInvoiceDocument(
                      invoice.init.isReversedOrReversal ||
                        Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                        toBeReversed,
                      (message) => setNotificationMessage(message),
                      (id: string) => {
                        const staffId = !!decodedToken.current
                          ? decodedToken.current.staffId
                          : "";
                        dispatch(
                          createDocument({
                            precedentId: item.guid,
                            matterId: matterDetails?.matterId,
                            documentName: item.description,
                            staffInitials: userList?.find(
                              (s) => s.staffId === staffId
                            )?.initials,
                            isCustomPrecedent: false,
                            parameters: {
                              invoiceId: currentInvoiceDetails?.invoiceId || "",
                              staffId: staffId,
                            },
                          } as IDocBuilderModelDto)
                        ).then((action: any) => {
                          const docModel = action.payload as IDocModelDto;
                          openDocument(
                            {
                              documentId: docModel.documentId,
                              matterId: matterDetails?.matterId,
                            },
                            sdkApi
                          );
                        });
                      },
                      (id) => dispatch(setPrecedentId(id)),
                      currentInvoiceDetails,
                      item.guid
                    );
                    setPrecedentMenuAnchorEl(null);
                  }}
                  value={item.guid}
                >
                  {item.code} - {item.description}
                </MenuItem>
              ))}
            </Menu>
            <Tooltip placement="bottom-end" title="Print">
              <IconButton
                disableRipple
                disableTouchRipple
                onClick={() => {
                  printReport(
                    () =>
                      setNotificationMessage(
                        "This transaction will be printed after saving."
                      ),
                    () =>
                      openReport(
                        currentInvoiceDetails?.invoiceId || "",
                        sdkApi
                      ),
                    () => dispatch(setPrintingEnabled(true)),
                    currentInvoiceDetails
                  );
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="print" src={printerIcon} />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <div className="invoice-main">
        <>
          <div className="invoice-criteria">{renderCriteria()}</div>
          <div
            className={
              isBoltOn()
                ? "invoice-breakdown invoice-breakdown-bolt-on"
                : "invoice-breakdown"
            }
          >
            {renderBreakdown()}
          </div>
          <div className="invoice-total">{renderTotals()}</div>
        </>
      </div>
      <Footer
        onCancel={() => {
          if (
            JSON.stringify(invoice.invoice) !==
            JSON.stringify(currentInvoiceDetails)
          ) {
            setShowUnsavedData(true);
          } else {
            handleClose();
          }
        }}
        onSave={() => {
          if (toBeReversed) {
            if (currentInvoiceDetails?.invoiceId) {
              dispatch(reverseInvoice(currentInvoiceDetails))
                .unwrap()
                .then(() => {
                  dispatch(
                    clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                  )
                    .then(() => close(sdkApi))
                    .catch((e) => console.error(e));
                });
            }
          } else {
            if (
              !!currentInvoiceDetails &&
              !validate(
                getRequiredFields(currentInvoiceDetails),
                [],
                (errors) => dispatch(setValidationErrors(errors))
              )?.length &&
              !saving
            ) {
              const totals = getTotals();
              if (totals.totalCost + totals.totalVat > 0) {
                dispatch(setLoader(true));
                if (currentInvoiceDetails.invoiceId) {
                  if (
                    currentInvoiceDetails.status !== invoice.invoice?.status
                  ) {
                    dispatch(
                      updateInvoice(
                        convertFormModelToInvoice(currentInvoiceDetails)
                      )
                    )
                      .unwrap()
                      .then(() => {
                        dispatch(
                          clearSemaphore(
                            currentInvoiceDetails.semaphoreId || ""
                          )
                        )
                          .then(() => close(sdkApi))
                          .catch((e) => console.error(e));
                      });
                  } else {
                    dispatch(
                      clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                    )
                      .then(() => close(sdkApi))
                      .catch((e) => console.error(e));
                  }
                } else {
                  dispatch(
                    createInvoice(
                      convertFormModelToInvoice(currentInvoiceDetails)
                    )
                  )
                    .unwrap()
                    .then((payload: IInvoiceDto) => {
                      dispatch(
                        clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                      ).then(() => {
                        if (invoice.precedentId) {
                          const staffId = !!decodedToken.current
                            ? decodedToken.current.staffId
                            : "";
                          dispatch(
                            createDocument({
                              precedentId: invoice.precedentId,
                              matterId: payload.matterId,
                              documentName: invoice.precedentList?.find(
                                (item) => item.guid === invoice.precedentId
                              )?.description,
                              staffInitials: userList?.find(
                                (s) => s.staffId === staffId
                              )?.initials,
                              parameters: {
                                invoiceId: payload.invoiceId,
                                staffId: staffId,
                              },
                            } as IDocBuilderModelDto)
                          ).then((action: any) => {
                            const docModel = action.payload as IDocModelDto;
                            openDocument(
                              {
                                documentId: docModel.documentId,
                                matterId: payload.matterId,
                              },
                              sdkApi
                            );
                            if (
                              invoice.isPrintingEnabled &&
                              docModel.documentId &&
                              payload.invoiceId
                            ) {
                              openReport(payload.invoiceId, sdkApi).then(() =>
                                close(sdkApi)
                              );
                            } else {
                              close(sdkApi);
                            }
                          });
                        } else if (
                          invoice.isPrintingEnabled &&
                          payload.invoiceId
                        ) {
                          openReport(payload.invoiceId, sdkApi).then(() =>
                            close(sdkApi)
                          );
                        } else close(sdkApi);
                      });
                    })
                    .catch((e) => console.error(e));
                }
              } else
                dispatch(
                  setValidationErrors([
                    "You cannot create an invoice with a value of £0.00",
                  ])
                );
            }
          }
        }}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                setNotificationMessage(undefined);
              }}
              variant="contained"
            >
              OK
            </Button>
          </>
        }
        content={<Typography>{notificationMessage}</Typography>}
        isOpen={notificationMessage !== undefined}
        onClose={() => setNotificationMessage(undefined)}
        title="Notification"
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => {
          setShowUnsavedData(false);
        }}
        onYesCallback={() => handleClose()}
      />
      <InvoiceErrorDialog />
    </>
  );
};

export default InvoiceCivilDetails;
